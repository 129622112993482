body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


  height:100vh;
  box-sizing:border-box;
  padding-top: 30px;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.title {
  font-family: 'Playfair Display', serif;
  font-size: 64px;
  text-align: left;
  font-weight: 600;
}

.subtitle {
  font-family: 'Playfair Display', serif;
  font-size: large;
  text-align: left;
  font-weight: 500;
}

.hidden-title {
  font-family: 'Playfair Display', serif;
  font-size: x-small;
  text-align: left;
}



.fancy-text {
  text-align: left;
  font-weight: 500;
  letter-spacing: 1px;
}

.fancy-navbar-item {
  font-family: 'Playfair Display', serif;
}

.backgroundimg {
  background-image: url('./wedding-1-2.jpg');
  background-position: 75% 35%;
  background-size: cover;
  overflow-y: scroll;
}

.background-location {
  background-image: url('./place2');
  background-size: cover;
  overflow-y: scroll;
  background-position: 75% 0%;
}

.backgroundcolor {
  background-color: #b0c7d5;
  height: 100%;
}

html, body{
  height: 100%;
}

maxheight {
  height: 100%;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.bg-primary {
  background-color: rgba(0, 110, 206, 0.199)!important;
}

.highlighted {
  color: white;
}




ul.timeline-3 {
  list-style-type: none;
  position: relative;
}
ul.timeline-3:before {
  content: " ";
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}
ul.timeline-3 > li {
  margin: 20px 0;
  padding-left: 20px;
}
ul.timeline-3 > li:before {
  content: " ";
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #22c0e8;
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 400;
}


.parallax-landing-volgymajor {
  background-image: url('images/volgymajor.jpg');
  background-size: cover;
  min-height: '100vh';
  background-position: 90% 0%;
}

.parallax-landing-img {
  background-image: url('images/landing.jpg');
  background-size: cover;
  min-height: '100vh';
  background-position: 75% 35%;
}

.parallax-landing-img-l0 {
  background-image: url('images/landing_layer_0.png');
  background-size: cover;
  min-height: '100vh';
  background-position: 75% 35%;
}

.parallax-landing-img-lbackground {
  background-image: url('images/landing_layer_background.png');
  background-size: cover;
  min-height: '100vh';
  background-position: 75% 35%;
}

.parallax-landing-img-l2 {
  background-image: url('images/landing_layer_2.png');
  background-size: cover;
  min-height: '100vh';
  background-position: 75% 35%;
}

.parallax-landing-img-l3 {
  background-image: url('images/landing_layer_3.png');
  background-size: cover;
  min-height: '100vh';
  background-position: 75% 35%;
}

.parallax-landing-img-l4 {
  background-image: url('images/landing_layer_4.png');
  background-size: cover;
  min-height: '100vh';
  background-position: 75% 35%;
}

.parallax-landing-img-back {
  background-size: cover;
  min-height: '100vh';
  background-position: 75% 35%;
}

.parallax-landing-img-cloud {
  background-size: cover;
  min-height: '100px';
}

.overlay{
  background:transparent;
  position:relative;
}

.logo > path {
  fill: "white";
  stroke: 'red';
}
